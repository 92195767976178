const Invisible_Grill = 
<div>
  <h5>
    <b>Material</b> - Aluminum channel and polyster fiber net 
  </h5>
  <h5>
    <b>Purpose</b> - 100% solution from mosquito 
  </h5>
  <h5>
    <b>For order</b> - 9970007890 
  </h5>
  <h5>
    <b>Location</b> - Mumbai and Navi Mumbai 
  </h5>
</div>

const Mosquito_net = 
<div>
  <h5>
    <b>Material</b> - 316 ss rope with aliminium track
  </h5>
  <h5>
    <b>Warranty</b> - 5 years
  </h5>
  <h5>
    <b>Advantage</b>
    <ol>
      <li>Non rustic Material</li>
      <li>Transperant view</li>
      <li>Zero maintainance</li>
      <li>Load carrying capacity 450kg</li>
    </ol>  
  </h5>
</div>

const Grass = 
<div>
  <h5><b>Artificial grass benefits homeowners in a variety of ways</b></h5>
  <h5>
    <ul>
      <li>Lawn Health</li>
      <li>Weeds & Pests</li>
      <li>Mud & Puddles</li>
      <li>Stop Buyinh Fertilizers</li>
      <li>No Need for Pesticides</li>
      <li>Avoid Water Restriction Fines</li>
      <li>Reduce Weekend Maintenance</li>
      <li>Better Life Expectancy</li>
    </ul>
  </h5>
</div>

const Spikes = 
<div>
  <h5>
    <h4>Bird Spikes</h4>
    We provide the best Anti Bird Spikes service.
    A bird control spike also known aas a anti-roosting spike,
    pigein spike, or roost modification.
    This device consists of long needle-like rods use for bird control
  </h5>
  <h5>
    <h4>Pigeon Net</h4>
    Bird protection nets are an effective and humane method of bird 
    exclusion. Bird nets can be used to protect all kinds of objects, 
    openings, and structures as there is a major bird problem in many 
    areas nowadays.
  </h5>
</div>

const Flooring = 
<h5>
  <ol>
    <li>Enhance the look of your home.</li>
    <li>Low Maintenance & Easy to clean</li>
    <li>Strong & Durable</li>
    <li>Adds value to your home</li>
    <li>Better Air Quality</li>
    <li>Looks Good in All Decor theme</li>
    <li>Long-Lasting & Cost-Effective</li>
    <li>Color Doesn't Fade</li>
  </ol>
</h5>

const Wallpaper = 
<div>
  <h5>Thanks to its versatility, you can use wallpaper in any room of the house, whether
    in the living room, bedroom or bathroom.
  </h5>
  <h5>
    It can give a splash of color and variety to the walls of your home, 
    helping you to create a unique style
  </h5>
  <h5>
    Here we'll tell you about the advantages and drawbacks of using wallpaper
  </h5>
</div>

const Blinds = 
<div>
  <h5>Sunscreen roller blinds keep out the heat and harmful UV rays</h5>
  <h5>This also decreases the need for air conditioning and you can also save power on artificial lighting because you are not blocking natural lights</h5>
</div>

const monsoon = 
<div>
  <h5>These custom fit blinds withstand Indian tropical weather effects such as rain, wind, harsh glare or heat.</h5>
  <h5>The monsoon blinds themselves are easy to handle and maintain. Ideal for for open areas.</h5>
</div>

export {
  Invisible_Grill,
  Mosquito_net,
  Grass,
  Spikes,
  Blinds,
  Wallpaper,
  Flooring,
  monsoon
}