
import L1 from '../assests/png/living/1.png'
import L2 from '../assests/png/living/2.png'
import L3 from '../assests/png/living/3.png'
import L4 from '../assests/png/living/4.png'
import L5 from '../assests/png/living/5.png'
import L6 from '../assests/png/living/6.png'

import K1 from '../assests/png/kitchen/1.png'
import K2 from '../assests/png/kitchen/2.png'
import K3 from '../assests/png/kitchen/3.png'
import K4 from '../assests/png/kitchen/4.png'
import K5 from '../assests/png/kitchen/5.png'
import K6 from '../assests/png/kitchen/6.png'

import B1 from '../assests/png/bathroom/1.png'
import B2 from '../assests/png/bathroom/2.png'
import B3 from '../assests/png/bathroom/3.png'
import B4 from '../assests/png/bathroom/4.png'
import B5 from '../assests/png/bathroom/5.png'
import B6 from '../assests/png/bathroom/6.png'

import Bd1 from '../assests/png/bedroom/1.png'
import Bd2 from '../assests/png/bedroom/2.png'
import Bd3 from '../assests/png/bedroom/3.png'
import Bd4 from '../assests/png/bedroom/4.png'
import Bd5 from '../assests/png/bedroom/5.png'
import Bd6 from '../assests/png/bedroom/6.png'

import Bc1 from '../assests/png/balcony/1.png'
import Bc2 from '../assests/png/balcony/2.png'
import Bc3 from '../assests/png/balcony/3.png'
import Bc4 from '../assests/png/balcony/4.png'

import O1 from '../assests/png/other/1.png'
import O2 from '../assests/png/other/2.png'
import O3 from '../assests/png/other/3.png'
import O4 from '../assests/png/other/4.png'
import O5 from '../assests/png/other/5.png'
import O6 from '../assests/png/other/6.png'
import O7 from '../assests/png/other/7.png'

import P1 from '../assests/png/product/1.png'
import P2 from '../assests/png/product/2.png'
import P3 from '../assests/png/product/3.png'
import P4 from '../assests/png/product/4.png'
import P5 from '../assests/png/product/5.png'

import S1 from '../assests/png/service/1.png'
import S2 from '../assests/png/service/2.png'
import S3 from '../assests/png/service/3.png'
import S4 from '../assests/png/service/4.png'

import A1 from '../assests/png/about/1.png'
import A2 from '../assests/png/about/2.png'
import A3 from '../assests/png/about/3.png'
import A4 from '../assests/png/about/4.png'
import A5 from '../assests/png/about/5.png'

// product import section
import Wall1 from '../assests/png/service/wallpaper/1.png'
import Wall2 from '../assests/png/service/wallpaper/2.png'
import InvG1 from '../assests/png/service/invisible_grill/1.png'
import InvG2 from '../assests/png/service/invisible_grill/2.png'

import MosNet1 from '../assests/png/service/mosquite_net/1.png'
import MosNet2 from '../assests/png/service/mosquite_net/2.png'

import GarArt1 from '../assests/png/service/garden_artificial/1.png'
import GarArt2 from '../assests/png/service/garden_artificial/2.png'
import GarArt3 from '../assests/png/service/garden_artificial/3.png'

import BirdS1 from '../assests/png/service/bird_spikes/1.png'
import BirdS2 from '../assests/png/service/bird_spikes/2.png'

import Flr1 from '../assests/png/service/flooring/1.png'
import Flr2 from '../assests/png/service/flooring/2.png'

import MonB1 from '../assests/png/service/monsoon_blinds/1.png'
import MonB2 from '../assests/png/service/monsoon_blinds/2.png'

import WodB1 from '../assests/png/service/wooden_blinds/1.png'
import WodB2 from '../assests/png/service/wooden_blinds/2.png'

import { Invisible_Grill, Mosquito_net, Grass, Spikes, Blinds, Wallpaper, Flooring, monsoon } from '../config/productDetails'

const productDetailsImage = [
  {
    name : "Invisible Grill",
    image: [InvG1, InvG2],
    description: Invisible_Grill
  },
  {
    name : "Mosqito Net",
    image: [MosNet1, MosNet2],
    description: Mosquito_net
  },
  {
    name : "Wall Garden And Artificial",
    image: [GarArt1, GarArt2, GarArt3],
    description: Grass
  },
  {
    name : "Bird Spikes & Pigeon",
    image: [BirdS1, BirdS2],
    description: Spikes
  },
  {
    name : "Burna Teak Wood Floring And Furniture",
    image: [Flr1, Flr2],
    description: Flooring
  },
  {
    name : "Monsoon Blinds",
    image: [MonB1, MonB2],
    description: monsoon
  },
  {
    name : "Waterproof Wallpaper",
    image: [Wall1, Wall2],
    description: Wallpaper
  },
  {
    name : "Sunscreen And Wooden Blinds",
    image: [WodB1, WodB2],
    description: Blinds
  }
]

const livingImage = [L1, L2, L3, L4, L5, L6]
const kitchenImage = [K1, K2, K3, K4, K5, K6]
const bathroomImage = [B1, B2, B3, B4, B5, B6]
const bedroomImage = [Bd1, Bd2, Bd3, Bd4, Bd5, Bd6]
const balconyImage = [Bc1, Bc2, Bc3, Bc4]
const otherImage = [O1, O2, O3, O4, O5, O6, O7]
const aboutImage = [A1, A2, A3, A4, A5]

const productImage = [
  {
    image:P1,
    name:"High quality invisible grill"
  },
  {
    image:P2,
    name: "Waterproof Wallpaper"
  },
  {
    image:P3,
    name: "Imported Mosquito net"
  },
  {
    image:P4,
    name: "Monsoon blinds"
  },
  {
    image: P5,
    name: "Pigeon net and bird spikes"
  }
]

const serviceImage = [
  {
    image:S1,
    name:"Living hall"
  },
  {
    image:S2,
    name: "Bedroom"
  },
  {
    image:S3,
    name: "Kitchen"
  },
  {
    image:S4,
    name: "Balcony"
  }
]


export {
  livingImage,
  kitchenImage,
  bathroomImage,
  bedroomImage,
  balconyImage,
  otherImage,
  productImage,
  serviceImage,
  aboutImage,
  productDetailsImage
}